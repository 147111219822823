import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import head from 'lodash/fp/head';
import debounce from 'lodash/fp/debounce';
import isEmpty from 'lodash/fp/isEmpty';

import { isMapView, makeRoute, type MakeRouteProp } from './routeIntents';
import {
    useActiveAssetId,
    useSelectedAssetGroupIds,
    useSelectedAssetIds,
    useSelectedDriverIds,
} from '../features/app/appHooks';
import {
    useActiveTab,
    useChunkCounter,
    useShowTableSettingsDialog,
    useViewType,
} from '../features/sidebar/sidebarHooks';
import { useIsServiceInfoDialogShown, useServiceInfoDialogChapter } from '../features/serviceInfo/serviceInfoSelectors';
import { useIsShareLinkDialogShown } from '../features/dialogs/shareLinkDialog/shareLinkSlice';
import { rawDataLayerPush } from '../configuration/setup/googleTagManager';
import { getBasePath } from './routes';
import { usePreviousLocation } from '../hooks/usePreviousLocation';
import {
    useCenter,
    useZoom,
    useMapType,
    useMapLayers,
    useShowCluster,
    useShowWorkshopPois,
    useShowCustomerPois,
    useShowGeofences,
    useShowChargingStations,
    useHasLockOnAsset,
} from '../features/map/mapHooks';
import {
    useTreeCategory,
    useTreePoiCategory,
    useIsTreeOpen,
    useShowFuelType,
    useShowEmptyGroups,
    useShowAssetGroups,
    useShowDriverGroups,
} from '../features/tree/treeHooks';
import {
    useActivityFilter,
    useAssetFilter,
    useColumns,
    useNotificationFilter,
    useSearchValue,
    useSortBy,
    useSortDir,
} from '../features/table/tableHooks';

const PUSH_ROUTE_DEBOUNCE = 200;

const useCommonRouteProps = () => {
    return {
        activeAssetId: useActiveAssetId(),
        tabId: useActiveTab(),
        showServiceInfoDialog: useIsServiceInfoDialogShown(),
        serviceInfoChapter: useServiceInfoDialogChapter(),
        showShareLinkDialog: useIsShareLinkDialogShown(),
    };
};

const useMapViewRouteProps = () => {
    const commonProps = useCommonRouteProps();
    return {
        ...commonProps,
        selectedAssetIds: useSelectedAssetIds(),
        selectedAssetGroupIds: useSelectedAssetGroupIds(),
        selectedDriverIds: useSelectedDriverIds(),
        treeCategory: useTreeCategory(),
        treePoiCategory: useTreePoiCategory(),
        isTreeOpen: useIsTreeOpen(),
        areEmptyGroupsShown: useShowEmptyGroups(),
        areAssetGroupsShown: useShowAssetGroups(),
        areDriverGroupsShown: useShowDriverGroups(),
        isFuelTypeShown: useShowFuelType(),
        center: useCenter(),
        zoom: useZoom(),
        mapType: useMapType(),
        mapLayers: useMapLayers(),
        showCluster: useShowCluster(),
        showWorkshopPois: useShowWorkshopPois(),
        showCustomerPois: useShowCustomerPois(),
        showGeofences: useShowGeofences(),
        showChargingStations: useShowChargingStations(),
        lockOnAsset: useHasLockOnAsset(),
    } as MakeRouteProp;
};

const useTableViewRouteProps = () => {
    const commonProps = useCommonRouteProps();
    return {
        ...commonProps,
        tableSearch: useSearchValue(),
        sortBy: useSortBy(),
        sortDir: useSortDir(),
        columns: useColumns(),
        activityFilter: useActivityFilter(),
        assetFilter: useAssetFilter(),
        notificationFilter: useNotificationFilter(),
        chunks: useChunkCounter(),
        viewType: useViewType(),
        showTableSettings: useShowTableSettingsDialog(),
    } as MakeRouteProp;
};

// Check if location is list or map and return path and search queries accordingly. Note, this could
// lead to a redirect if check fails.
const useRouteFittingToState = () => {
    const mapRouteProps = useMapViewRouteProps();
    const tableRouteProps = useTableViewRouteProps();
    return isMapView() ? makeRoute(mapRouteProps) : makeRoute(tableRouteProps);
};

export const getFirstLevelOfUrl = (path: string) => head(path.match(/\/\w*/));

export const RouterUpdater = () => {
    const [lastPath, setLastPath] = useState<string | undefined>(getBasePath());
    const navigate = useNavigate();
    const location = useLocation();
    const prevLocation = usePreviousLocation(location);

    const routeToPushTo = useRouteFittingToState();

    // Use memo to ensure debounce function is stable on each render cycle
    const pushRoute = useMemo(() => debounce(PUSH_ROUTE_DEBOUNCE)((route: string) => navigate(route)), [navigate]);

    const handleRouteUpdates = useCallback(() => {
        const currentRoute = `${location.pathname}${location.search}`;
        const newBasePath = getFirstLevelOfUrl(routeToPushTo);

        if (!prevLocation && !isEmpty(location.search)) {
            // Avoid loop on first load
            return;
        }

        if (routeToPushTo !== currentRoute) {
            if (lastPath !== newBasePath) {
                // Change main view
                navigate(routeToPushTo, { replace: true });
                setLastPath(newBasePath);
                rawDataLayerPush({
                    event: 'virtPath',
                    virtPath: newBasePath,
                });
            } else {
                pushRoute(routeToPushTo);
            }
        }
    }, [location, routeToPushTo, lastPath, prevLocation, pushRoute, navigate]);

    useEffect(() => {
        handleRouteUpdates();
    }, [routeToPushTo]);

    return null;
};
