import { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { sortByProperty } from '@rio-cloud/rio-uikit/SortUtils';
import isEmpty from 'lodash/fp/isEmpty';

import { sidebarActions } from '../sidebarActions';
import { getTransformedData } from '../../app/selectors';
import { getClusterAssetIds } from '../../map/mapSelectors';
import { clusterDeselected, mapContainerSizeChanged } from '../../map/mapSlice';
import { showActiveAsset } from '../../map/mapThunks';
import { ClusterSidebarContent } from './ClusterSidebarContent';
import { ClusterSidebarFooter } from './ClusterSidebarFooter';
import { useMapResizerOnSidebarToggle } from '../../../hooks/useMapResizerOnSidebarToggle';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { SIDEBAR_DEFAULT_WIDTH, SIDEBAR_MIN_WIDTH, SIDEBAR_MODE_BREAKPOINT } from '../sidebarSelectors';

export const ClusterSidebar = memo(props => {
    const {
        clusterAssetIds,
        deselectCluster,
        transformedData,
        selectAssetFromCluster,
        className = '',
        updateMapContainer,
    } = props;

    const assets = transformedData.filter(asset => clusterAssetIds.includes(asset.vehicleId));
    const hasAssets = !isEmpty(assets);

    useMapResizerOnSidebarToggle(hasAssets, updateMapContainer);

    if (!hasAssets) {
        return null;
    }

    const sortedAssets = sortByProperty(assets, 'name');

    const handleClose = () => {
        updateMapContainer();
        deselectCluster();
        gaPush({
            category: TRACKING_CATEGORIES.CLUSTER_DETAILS,
            action: 'Cluster Details Closed',
            label: 'Closed Cluster Details',
        });
    };
    const handleSelectAsset = assetId => {
        selectAssetFromCluster(assetId);
        gaPush({
            category: TRACKING_CATEGORIES.CLUSTER_DETAILS,
            action: 'Cluster Asset Clicked',
            label: 'Clicked Cluster Asset',
        });
    };

    return (
        <Sidebar
            title={<FormattedMessage id={'intl-msg:livemonitor.map.clusterDetails.title'} />}
            className={`ClusterDetails ${className}`}
            titleClassName='padding-left-10'
            closed={false}
            onClose={handleClose}
            footer={<ClusterSidebarFooter onClose={handleClose} />}
            position={Sidebar.RIGHT}
            resizable
            onResizeEnd={updateMapContainer}
            width={SIDEBAR_DEFAULT_WIDTH}
            minWidth={SIDEBAR_MIN_WIDTH}
            switchModeBreakpoint={SIDEBAR_MODE_BREAKPOINT}
            fly
            enableFullscreenToggle={false}
        >
            <ClusterSidebarContent assets={sortedAssets} onSelectAsset={handleSelectAsset} />
        </Sidebar>
    );
});

ClusterSidebar.propTypes = {
    className: PropTypes.string,
    clusterAssetIds: PropTypes.array,
    deselectCluster: PropTypes.func,
    selectAssetFromCluster: PropTypes.func,
    transformedData: PropTypes.array,
    updateMapContainer: PropTypes.func,
};

const mapStateToProps = state => ({
    clusterAssetIds: getClusterAssetIds(state),
    transformedData: getTransformedData(state),
});

const mapDispatchToProps = dispatch => ({
    deselectCluster: () => dispatch(clusterDeselected()),
    selectAssetFromCluster: assetId => {
        dispatch(sidebarActions.assetSelectedFromClusterSidebar(assetId));
        dispatch(showActiveAsset);
    },
    updateMapContainer: () => dispatch(mapContainerSizeChanged()),
});

const ClusterSidebarContainer = connect(mapStateToProps, mapDispatchToProps)(ClusterSidebar);

export default ClusterSidebarContainer;
