import { getBasePath } from '../../routes/routes';

declare global {
    interface Window {
        // biome-ignore lint/suspicious/noExplicitAny: I guess we need to define a type
        dataLayer?: any[];
    }
}
export interface GAEventData {
    event: string;
    virtPath?: string;
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
}

export interface GAEvent {
    category: string;
    action: string;
    label: string;
}

export const rawDataLayerPush = (gaEvent: GAEventData) => {
    if (window.dataLayer) {
        window.dataLayer.push(gaEvent);
    }
};

rawDataLayerPush({
    event: 'virtPath',
    virtPath: getBasePath(),
});

export const gaPush = (event: GAEvent) =>
    rawDataLayerPush({
        event: 'ga_event',
        eventCategory: event.category,
        eventAction: event.action,
        eventLabel: event.label,
    });

// categories as defined for all services on:
// https://collaboration.msi.audi.com/confluence/display/MAN/Overarching+Topics
export const TRACKING_CATEGORIES = {
    NAVIGATION: 'livemonitor, navigations',
    TABLE: 'livemonitor, table',
    MAP: 'livemonitor, map',
    TREE: 'livemonitor, tree',
    ASSET_TREE: 'livemonitor, tree, assets',
    DRIVER_TREE: 'livemonitor, tree, drivers',
    SEARCH_TREE: 'livemonitor, tree, search',
    POI_TREE: 'livemonitor, tree, pois',
    GEOFENCE_TREE: 'livemonitor, tree, geofences',
    ASSET_DETAILS: 'livemonitor, details, asset',
    CLUSTER_DETAILS: 'livemonitor, details, cluster',
    POI_DETAILS: 'livemonitor, details, poi',
    CHARGING_STATION_DETAILS: 'livemonitor, details, chargingStation',
    INT_LINK: 'livemonitor, internalLinks',
    SERVICE_INFO: 'livemonitor, serviceInfo',
    UPSELLING: 'livemonitor, upselling',
    DELIVERY_STATUS_TEST: 'livemonitor, deliveryStatusTest',
    PARTNER_NETWORK_EXPERIMENT: 'livemonitor, partnerNetworkExperiment',
    PROMOTION: 'livemonitor, promotion',
};

export const TRACKING_ACTIONS = {};

export const TRACKING_LABELS = {};

export const executeAndTrack =
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type
        (func: () => void, val: any) =>
        (...args) => {
            func.apply(null, args);
            rawDataLayerPush(val);
        };

export const trackingAttributes = (category: string, action: string, label?: string, value?: string) => {
    const attributes: Record<string, string> = {
        'data-track-trigger': 'click',
        'data-track-category': category,
        'data-track-action': action,
    };

    if (label) {
        attributes['data-track-label'] = label;
    }

    if (value) {
        attributes['data-track-value'] = value;
    }

    return attributes;
};
