import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { actions as appActions } from '../../services/actions';
import {
    ROUTEPLANNING_WIDGET,
    HISTORY_WIDGET,
    POI_DETAILS_WIDGET,
    GEOFENCE_DETAILS_WIDGET,
    ACTIVITY_WIDGET,
    TOURS_WIDGET,
    SMART_ROUTEPLANNING_SETTINGS_WIDGET,
    RANGE_WIDGET,
    CHARGING_STATION_SEARCH_WIDGET,
    CHARGING_STATION_DETAILS_WIDGET,
    SIMPLE_PAY_POI_DETAILS_WIDGET,
} from './widgetsConfig';

type WidgetState = {
    visible: boolean;
};

type WidgetsState = Record<string, WidgetState>;

export const widgetState = {
    visible: false,
};

export const defaultWidgetsState: WidgetsState = {
    [ROUTEPLANNING_WIDGET.name]: widgetState,
    [SMART_ROUTEPLANNING_SETTINGS_WIDGET.name]: widgetState,
    [RANGE_WIDGET.name]: widgetState,
    [CHARGING_STATION_SEARCH_WIDGET.name]: widgetState,
    [CHARGING_STATION_DETAILS_WIDGET.name]: widgetState,
    [HISTORY_WIDGET.name]: widgetState,
    [POI_DETAILS_WIDGET.name]: widgetState,
    [GEOFENCE_DETAILS_WIDGET.name]: widgetState,
    [ACTIVITY_WIDGET.name]: widgetState,
    [TOURS_WIDGET.name]: widgetState,
    [SIMPLE_PAY_POI_DETAILS_WIDGET.name]: widgetState,
};

export const widgetsSlice = createSlice({
    name: 'widgets',
    initialState: defaultWidgetsState,
    reducers: {
        atLeastOneReducerNeedToBeDefinedToReturnInitialState: (state: WidgetsState) => ({
            ...state,
        }),
    },
    extraReducers: builder => {
        builder.addCase(appActions.widgetShown.toString(), (state: WidgetsState, action: PayloadAction<string>) => {
            state[action.payload].visible = true;
        });
        builder.addCase(appActions.widgetHidden.toString(), (state: WidgetsState, action: PayloadAction<string>) => {
            state[action.payload].visible = false;
        });
    },
});

export { appActions };

export default widgetsSlice.reducer;
