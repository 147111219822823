import { useAppSelector } from '../../configuration/setup/hooks';
import { getActiveGeofenceId } from '../app/selectors';
import {
    getActiveAdditionalLayers,
    getBoundingBox,
    getBoundingBoxReadOnly,
    getCenter,
    getClusterChargingStationIds,
    getClusterSimplePayPoiIds,
    getContextChargingStations,
    getContextSimplePayPois,
    getContextMapForCurrentContext,
    getContextMarkers,
    getContextRoute,
    getContextShapes,
    getCurrentMapContext,
    getFilteredMapAssetData,
    getGlobalSearchMarker,
    getHereSettings,
    getHereSettingsError,
    getLockOnAsset,
    getMapContainerSizeTimestamp,
    getMapCustomerPois,
    getMapGeofences,
    getMapLayers,
    getMapRendering,
    getMapType,
    getMapWorkshopPois,
    getRoutePlanningMarkers,
    getRoutePlanningRoute,
    getShowChargingStations,
    getShowSimplePayPois,
    getShowCluster,
    getShowCustomerPois,
    getShowGeofences,
    getShowWorkshopPois,
    getZoom,
    hasActiveMapFilter,
    hasMapLoaded,
} from './mapSelectors';

export const useMapGeofences = () => useAppSelector(getMapGeofences);
export const useShowGeofences = () => useAppSelector(getShowGeofences);
export const useActiveGeofence = () => useAppSelector(getActiveGeofenceId);

export const useHereSettings = () => useAppSelector(getHereSettings);
export const useHereSettingsError = () => useAppSelector(getHereSettingsError);
export const useHasLockOnAsset = () => useAppSelector(getLockOnAsset);

export const useChargingStations = () => useAppSelector(getContextChargingStations);
export const useShowChargingStations = () => useAppSelector(getShowChargingStations);
export const useChargingStationClusterIds = () => useAppSelector(getClusterChargingStationIds);

export const useSimplePayPois = () => useAppSelector(getContextSimplePayPois);
export const useShowSimplePayPois = () => useAppSelector(getShowSimplePayPois);
export const useSimplePayPoiClusterIds = () => useAppSelector(getClusterSimplePayPoiIds);

export const useFilteredAssets = () => useAppSelector(getFilteredMapAssetData);
export const useHasActiveMapFilter = () => useAppSelector(hasActiveMapFilter);

export const useContextMarkers = () => useAppSelector(getContextMarkers);
export const useGlobalSearchMarkers = () => useAppSelector(getGlobalSearchMarker);
export const useRoutePlanningMarkers = () => useAppSelector(getRoutePlanningMarkers);

export const useShowWorkshopPois = () => useAppSelector(getShowWorkshopPois);
export const useShowCustomerPois = () => useAppSelector(getShowCustomerPois);
export const useWorkshopPois = () => useAppSelector(getMapWorkshopPois);
export const useCustomerPois = () => useAppSelector(getMapCustomerPois);
export const useActiveAdditionalLayers = () => useAppSelector(getActiveAdditionalLayers);

export const useRoute = () => useAppSelector(getContextRoute);
export const useRoutePlanningRoute = () => useAppSelector(getRoutePlanningRoute);

export const useShapes = () => useAppSelector(getContextShapes);

export const useMapType = () => useAppSelector(getMapType);
export const useMapLayers = () => useAppSelector(getMapLayers);

export const useCenter = () => useAppSelector(getCenter);
export const useContextMap = () => useAppSelector(getContextMapForCurrentContext);
export const useCurrentMapContext = () => useAppSelector(getCurrentMapContext);

export const useBoundingBox = () => useAppSelector(getBoundingBox);
export const useBoundingBoxReadOnly = () => useAppSelector(getBoundingBoxReadOnly);

export const useZoom = () => useAppSelector(getZoom);

export const useShowCluster = () => useAppSelector(getShowCluster);

export const useIsMapLoaded = () => useAppSelector(hasMapLoaded);
export const useMapRendering = () => useAppSelector(getMapRendering);

export const useMapContainerSizeTimestamp = () => useAppSelector(getMapContainerSizeTimestamp);
